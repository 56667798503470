import React from "react";

function AddCartIcon(props) {
  return (
    <span>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="4" fill="#00AC57" />
        <path d="M16 9V23" stroke="white" strokeWidth="2" />
        <path d="M23 15.999L9 16.0014" stroke="white" strokeWidth="2" />
      </svg>
    </span>
  );
}

export default AddCartIcon;
