const CarouselData = [
  {
    id: 0,
    imgSrc: `/assets/carousels/hero-carousel-1.svg`,
    placeHolder: "Palm Oil Mill",
  },
  {
    id: 1,
    imgSrc: `/assets/carousels/hero-carousel-2.svg`,
    placeHolder: "Palm Tree Plantation",
  },
  {
    id: 2,
    imgSrc: `/assets/carousels/hero-carousel-3.svg`,
    placeHolder: "Drug Production",
  },
  {
    id: 3,
    imgSrc: `/assets/carousels/hero-carousel-4.svg`,
    placeHolder: "Drug Production Lab",
  },
  {
    id: 4,
    imgSrc: `/assets/carousels/hero-carousel-5.svg`,
    placeHolder: "Consultation Services",
  },
  {
    id: 5,
    imgSrc: `/assets/carousels/hero-carousel-6.svg`,
    placeHolder: "Online Consultancy",
  },
];

export default CarouselData;
