import React from "react";

function UploadIcon(props) {
  return (
    <span>
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 6L11 2L15 6"
          stroke="#444844"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M11 2V15.2859"
          stroke="#444844"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M1 16V22H21V16"
          stroke="#444844"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
}

export default UploadIcon;
