import React from "react";

function SubtractCartIcon(props) {
  return (
    <span>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="4" fill="#00AC57" />
        <path d="M23 16L9 16.0024" stroke="white" strokeWidth="2" />
      </svg>
    </span>
  );
}

export default SubtractCartIcon;
